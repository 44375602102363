import React from "react";
import { BsArrowsFullscreen } from "react-icons/bs";

const Gallery3D = ({ artistId, exhibitionId, fullSize }) => {
  const exhibitionPath = `https://businessland.io/meta/${exhibitionId}`;

  return (
    <div className={`${fullSize && "fullscreen"} relative w-full h-full`}>
      <iframe
        allowFullScreen={true}
        frameBorder="0"
        src={exhibitionPath}
        className="w-full h-full"
      ></iframe>

      {!fullSize && (
        <a
          className="absolute top-4 left-4"
          href={`/artist/${artistId}/exhibition/${exhibitionId}`}
          target="_blank"
          rel="noreferrer"
        >
          <BsArrowsFullscreen className="text-white w-6 h-6 cursor-pointer" />
        </a>
      )}
    </div>
  );
};

export default Gallery3D;
